<template>
  <div class="roadmap" id="roadMap-1" v-if="room">

    <!-- 테이블 상태값 클래스 checking, shuffle, selected -->
    <div class="road_mask shuffling" style="display: block;"
         v-if="!room.game || room.game.state === 'maintenance' || room.game.state === 'shoechange'"></div>

    <!-- 로드맵 반복 시작 -->
    <div class="letsgame">
      <div class="info">
        <div class="room">
          <img :src="`/imgs/company/${company.key}.png`" class="game_box_img" style="width: 100px;">
          <div v-if="company.key === 'dream_gaming' && (room.name === '1' || room.name === '2')">
            {{ room.name }}
          </div>
          <div v-else>
            {{ this.lobbyName }}&nbsp; - &nbsp;{{ room.name }}
          </div>

        </div>
        <div class="game_status waiting"
             v-if="room && room.game && (room.game.state === 'stop' || room.game.state === 'close')">대기중
        </div>
        <div class="game_status waiting"
             v-if="room && room.game && (room.game.state === 'betting' || room.game.state === 'nomorebet')">게임중
        </div>
        <div class="game_status waiting" v-if="room && room.game && room.game.state === 'shoechange'">셔플중</div>
        <div class="game_status waiting" v-if="!room.game || room.game.state === 'maintenance'">점검중</div>

        <div class="gameNum">{{ count.b + count.p + count.t + 1 }}회차</div>
        <div class="timer_wrap">
          <div class="timer"><div v-if="room && room.game && room.game.state === 'betting'">{{room.game.second}} </div></div>
        </div>
        <ul class="no">
          <li class="b"
              style="background-image: url(/imgs/history/10.png); background-size: 24px; background-repeat: no-repeat; background-position: center left;">
            {{ count.b }}
          </li>
          <li class="p"
              style="background-image: url(/imgs/history/30.png); background-size: 24px; background-repeat: no-repeat; background-position: center left;">
            {{ count.p }}
          </li>
          <li class="t"
              style="background-image: url(/imgs/history/20.png); background-size: 24px; background-repeat: no-repeat; background-position: center left;">
            {{ count.t }}
          </li>
        </ul>
      </div>

      <div class="roadmap_wrap">
        <!--
        <div class="beadWrap"></div>
        <div class="bigeyeWrap"></div>
        <div class="smallWrap"></div>
        <div class="cockWrap"></div>
        -->

        <div class="table_rayout" @click="enterRoom">
          <lobby-history-panel-component :lobbyColType="lobbyColType" :lobbyHistoryType="lobbyHistoryType" :room="room"
                                         :histories="room.histories" :ref="'lobby-history-panel-' + room._id"/>
        </div>

      </div>

    </div>
    <!-- 로드맵 반복 끝 -->
  </div>
</template>

<script>
import LobbyHistoryPanelComponent from "@/components/lobby/lobby-history-panel-component2.vue"
import {STATE} from "@/helpers/constant.helper"
import {lobbyNames} from "@/helpers/string.helper";

export default {
  props: {
    lobbyColType: Number,
    lobbyHistoryType: Number,
    lobbyRoom: Object,
    company: Object,
  },
  components: {
    LobbyHistoryPanelComponent
  },
  data() {
    return {
      STATE,
      room: null,
      lobbyName: lobbyNames(this.company.key),
      isMouseOver: false,
      count: {
        p: 0,
        b: 0,
        t: 0
      },

    }
  },
  methods: {
    onLobbyRoomOver: function () {
      if (this.$API.util.isMobile()) return;
      if (this.room.state === 'maintenance') return;
      this.isMouseOver = true
    },
    onLobbyRoomOut: function () {
      if (this.$API.util.isMobile()) return;
      this.isMouseOver = false
    },
    enterRoom() {
      if (this.room.state === 'maintenance') return

      this.$API.util.playSoundClick()

      let roomType = localStorage.getItem('roomType')
      if (!roomType) roomType = '1'

      if (roomType === '1') {
        document.location.href = `/game/${this.company.key}/${this.room.key}?token=${this.$store.getters['auth/token']}&minmax=0`
      } else {
        document.location.href = `/game/${this.company.key}/${this.room.key}/wide?token=${this.$store.getters['auth/token']}&minmax=0`
      }
    },
    updateHistory() {
      const self = this
      setTimeout(() => {
        if (self.$refs['lobby-history-panel-' + self.room._id]) {
          self.$refs['lobby-history-panel-' + self.room._id].updateHistory()
          self.count = self.$refs['lobby-history-panel-' + self.room._id].getHistoryCount()
        }
      }, 100)
    },
    setState(state) {
      if (this.room && this.room.game) {
        // 슈체인지 이후 배팅 상태가 되면 출목을 초기화 한다.
        if (this.room.game.state === this.STATE.SHOE_CHANGE && state === this.STATE.BETTING) {
          this.room.histories = []
          this.room.game.historyLength = 0
          this.updateHistory()
        }

        if (this.room.game.state !== state) {
          this.room.game.state = state
        }
      }
    },
    setSecond() {

    }
  },
  mounted() {
    const self = this
    self.room = self.lobbyRoom
    setTimeout(() => {
      if (self.lobbyRoom) {
        self.updateHistory()
        if (self.$refs['lobby-history-panel-' + self.room._id]) {
          self.count = self.$refs['lobby-history-panel-' + self.room._id].getHistoryCount()
        }
      }
    }, 500)
  }
}
</script>